export function twitter(urlToShare) {
    return 'https://x.com/intent/tweet?text=' + urlToShare;
}

export function facebook(urlToShare) {
    return 'https://www.facebook.com/sharer/sharer.php?u=' + urlToShare;
}

export function pageLink(urlToShare) {
    if (process.client) {
        navigator.clipboard.writeText(urlToShare);
    }
}